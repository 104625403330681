import React from "react";
import { useMediaQuery } from "@material-ui/core";
import "../css/Style.css";

const Philosophy = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const isSmallWidth = useMediaQuery("(max-width: 380px)");
    return (
        <div className={isMobile ? "messageBox mobile" : "messageBox"}>
            <p className={isMobile ? "small anim white mobile" : "small anim white"}>
                사람은 태어난 순간부터 언제나 물과 함께합니다.
                {isSmallWidth ? <>&nbsp;</> : <br />}
                산에이는 그 근원적 연결을 위해 존재합니다.
                {isSmallWidth ? <>&nbsp;</> : <br />}
                시간이 흐르며 삶의 방식은 점점 다양해지고 있지만
                {isSmallWidth ? <>&nbsp;</> : <br />}
                인간과 물의 관계는 결코 변하지 않습니다.
            </p>
            <p className={isMobile ? "small anim white mobile" : "small anim white"}>
                1954년 창업 이래, 산에이는 언제나 사람들의 생활을 위해 공헌해왔습니다. "인간이 존재하는 한 물은 필요하다."라는 이념을
                바탕으로 물을 사용하는 다양한 경험을 제안합니다.
            </p>
        </div>
    );
};

export default React.memo(Philosophy);
