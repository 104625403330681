import React from "react";
import { useMediaQuery } from "@material-ui/core";
import "../css/Style.css";

const DesignOfWater = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    return (
        <div className={isMobile ? "messageBox mobile" : "messageBox"}>
            <p className={isMobile ? "small anim white mobile" : "small anim white"}>최적의 해답을 찾아 진화하는 물의 디자인.</p>
            <p className={isMobile ? "small anim white mobile" : "small anim white"}>
                물은 화학식으로는 H20. 단순히 2개의 수소와 1개의 산소로 구성된 무색무취의 존재입니다. 그러나 우리는 눈과 귀로, 피부의 감각을
                통해 매일 기분 좋은 물의 질감을 느낄 수 있습니다. 거기에 디자인의 힘이 있습니다. 사람과 사물의 관계를 더욱 좋게 만드는 것이
                디자인의 역할이라면 그것이 구성화한 것이 「수전」이 아닐까요? 수전을 형상화하는 과정이야말로, 물을 디자인하는 과정 그
                자체입니다.
            </p>
        </div>
    );
};

export default React.memo(DesignOfWater);
