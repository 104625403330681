import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import "./css/Style.css";
import Mobile from "./views/Mobile";
import Desktop from "./views/Desktop";

function App() {
    const isMobile = useMediaQuery("(max-width: 768px)");
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/sanei" />} />
                    <Route path="/sanei" element={isMobile ? <Mobile /> : <Desktop />} />
                    <Route path="*" element={<Navigate to="/sanei" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
    // return <>{isMobile ? <Mobile /> : <Desktop />}</>;
}

export default App;
