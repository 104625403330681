import React from "react";
import { useMediaQuery } from "@material-ui/core";
import "../css/Style.css";

const Vision = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    return (
        <div style={{ paddingLeft: "6%" }}>
            <p className={isMobile ? "large anim black mobile" : "large anim black"}>
                사람의 시선으로 바라봅니다.
                <br />
                물과 함께하는 편안한 생활을 위해
                <br />
                이상적인 조화를 제공하는 것이
                <br />
                산에이의 비전입니다.
            </p>
        </div>
    );
};

export default React.memo(Vision);
