import React from "react";
import { useMediaQuery } from "@material-ui/core";
import "../css/Style.css";

const Message = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    return (
        <div className={isMobile ? "messageBox mobile" : "messageBox"}>
            <p className={isMobile ? "small anim white mobile" : "small anim white"}>행동하자, 미래를 위해.</p>

            <p className={isMobile ? "small anim white mobile" : "small anim white"}>
                “인간이 존재하는 한 물은 필요하다.” 산에이의 철학은 오랜 역사에도 흔들림 없이 지켜져 왔습니다. 되려 모두가 지속가능성에 대해
                고민하는 오늘날에 더욱 절실한 의미로 다가옵니다.
            </p>
            <p className={isMobile ? "small anim white mobile" : "small anim white"}>
                지난 세기를 「석유의 시대」에 비유한다면 우리가 사는 21세기는 “물의 시대”라고 할 수 있습니다. 우리가 사용할 수 있는 물은
                점차 희소해지며, 물에 대한 전세계적인 우려는 실질적인 위협으로 확산되고 있습니다.
            </p>
            <p className={isMobile ? "small anim white mobile" : "small anim white"}>
                물의 순환과 직접적으로 연결되는 인류의 윤택한 삶과 휴식이 지속될 수 있도록, 미래를 위해 우리는 지금 행동해야 합니다.
            </p>
        </div>
    );
};

export default React.memo(Message);
