import React, { useLayoutEffect, useState } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import "../css/Style.css";

import { Vision, Philosophy, Message, DesignOfWater } from "../containers/index";
import {
    MonotonLogo,
    MonotonProduct,
    SuttoLogo,
    SuttoProduct,
    YoriSuttoLogo,
    YoriSuttoProduct,
    TohLogo,
    TohProduct,
    EddiesLogo,
    EddiesProduct,
    CyeLogo,
    CyeProduct,
    VisionBackground,
    PhilosophyBackground,
    MessageBackground,
    DesignOfWaterBackground,
    SaneiLogoBlack,
    SaneiLogoWhite,
    SaneiLogoCatalog,
    InstagramBlack,
    InstagramWhite,
} from "../img/index";

function Mobile() {
    const [background, setBackground] = useState(VisionBackground);
    const [logo, setLogo] = useState(SaneiLogoBlack);
    const [page, setPage] = useState(<Vision />);
    const [activeMenu, setActiveMenu] = useState(0);
    const isSmallWidth = useMediaQuery("(max-width: 400px)");
    const isMediumWidth = useMediaQuery("(max-width: 1024px)");
    const gridXs = 6;
    const gridMd = 6;

    useLayoutEffect(() => {
        if (activeMenu == 0) {
            setBackground(VisionBackground);
            setLogo(SaneiLogoBlack);
            setPage(<Vision />);
        } else if (activeMenu == 1) {
            setBackground(PhilosophyBackground);
            setLogo(SaneiLogoWhite);
            setPage(<Philosophy />);
        } else if (activeMenu == 2) {
            setBackground(MessageBackground);
            setLogo(SaneiLogoWhite);
            setPage(<Message />);
        } else if (activeMenu == 3) {
            setBackground(DesignOfWaterBackground);
            setLogo(SaneiLogoWhite);
            setPage(<DesignOfWater />);
        }
    }, [activeMenu]);

    return (
        <>
            <div
                className={activeMenu == 0 ? "leftContainer mobile small" : "leftContainer mobile large"}
                style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}
            >
                <div className="footer mobile">
                    <img className="logoImage" src={logo} />
                    <p className={activeMenu == 0 ? "logo black" : "logo white"}>
                        <a
                            className={activeMenu == 0 ? "black" : "white"}
                            href="https://www.instagram.com/msls.kr"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="instagramLogoImage" src={activeMenu == 0 ? InstagramBlack : InstagramWhite} alt="Instagram" />
                            &nbsp;&nbsp;인스타그램 바로가기
                        </a>
                    </p>
                    <p className={activeMenu == 0 ? "logo black" : "logo white"}>
                        1206, 168, Gonghang-daero, Gangseo-gu,{isSmallWidth ? <br /> : <>&nbsp;</>}Seoul, Republic of Korea
                        <br />
                        T. +82 (0)2-451-1994&nbsp;&nbsp;F.&nbsp;+82&nbsp;(0)2-451-1888
                        {isMediumWidth ? <br /> : <>&nbsp;&nbsp;</>}Copyright &copy; 2023 MINISURVEY Inc. All rights reserved.
                    </p>
                </div>
                <div className="menu mobile">
                    <button
                        className={activeMenu == 0 ? "defaultButton active mobile" : "plainButton mobile"}
                        onClick={() => {
                            setActiveMenu(0);
                        }}
                    >
                        비전
                    </button>
                    <button
                        className={
                            activeMenu == 1 ? "plainButton active mobile" : activeMenu == 0 ? "defaultButton mobile" : "plainButton mobile"
                        }
                        onClick={() => {
                            setActiveMenu(1);
                        }}
                    >
                        철학
                    </button>
                    <button
                        className={
                            activeMenu == 2 ? "plainButton active mobile" : activeMenu == 0 ? "defaultButton mobile" : "plainButton mobile"
                        }
                        onClick={() => {
                            setActiveMenu(2);
                        }}
                    >
                        메시지
                    </button>
                    <button
                        className={
                            activeMenu == 3 ? "plainButton active mobile" : activeMenu == 0 ? "defaultButton mobile" : "plainButton mobile"
                        }
                        onClick={() => {
                            setActiveMenu(3);
                        }}
                    >
                        물의 디자인
                    </button>
                </div>
                {page}
            </div>
            <div className="rightContainer mobile">
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ borderTop: "1px solid black" }}
                >
                    <Grid item xs={gridXs} md={gridMd} style={{ borderRight: "0.5px solid black" }}>
                        <Grid container direction="row" justifyContent="center" alignContent="center" className="gridBox">
                            <img className="productLogo" src={MonotonLogo} />
                            <p className="black xsmall">
                                Designed by
                                <br />
                                Iwakura Eiri
                            </p>
                            <img className="productImage" src={MonotonProduct} />
                        </Grid>
                    </Grid>
                    <Grid item xs={gridXs} md={gridMd} style={{ borderLeft: "0.5px solid black" }}>
                        <Grid container direction="row" justifyContent="center" alignContent="center" className="gridBox">
                            <img className="productLogo" src={SuttoLogo} />
                            <p className="black xsmall">
                                Designed by
                                <br />
                                Yasumichi Morita
                            </p>
                            <img className="productImage" src={SuttoProduct} />
                        </Grid>
                    </Grid>
                    <Grid item xs={gridXs} md={gridMd} style={{ borderRight: "0.5px solid black", borderTop: "1px solid black" }}>
                        <Grid container direction="row" justifyContent="center" alignContent="center" className="gridBox">
                            <img className="productLogo" src={YoriSuttoLogo} />
                            <p className="black xsmall">
                                Designed by
                                <br />
                                Yasumichi Morita
                            </p>
                            <img className="productImage" src={YoriSuttoProduct} />
                        </Grid>
                    </Grid>
                    <Grid item xs={gridXs} md={gridMd} style={{ borderLeft: "0.5px solid black", borderTop: "1px solid black" }}>
                        <Grid container direction="row" justifyContent="center" alignContent="center" className="gridBox">
                            <img className="productLogo" src={TohLogo} />
                            <p className="black xsmall">
                                Designed by
                                <br />
                                Yasumichi Morita
                            </p>
                            <img className="productImage" src={TohProduct} />
                        </Grid>
                    </Grid>
                    <Grid item xs={gridXs} md={gridMd} style={{ borderRight: "0.5px solid black", borderTop: "1px solid black" }}>
                        <Grid container direction="row" justifyContent="center" alignContent="center" className="gridBox">
                            <img className="productLogo" src={EddiesLogo} />
                            <p className="black xsmall">
                                Designed by
                                <br />
                                Edward Suzuki
                            </p>
                            <img className="productImage" src={EddiesProduct} />
                        </Grid>
                    </Grid>
                    <Grid item xs={gridXs} md={gridMd} style={{ borderLeft: "0.5px solid black", borderTop: "1px solid black" }}>
                        <Grid container direction="row" justifyContent="center" alignContent="center" className="gridBox">
                            <img className="productLogo" src={CyeLogo} />
                            <p className="black xsmall">
                                Designed by
                                <br />
                                SUPPOSE DESIGN OFFICE
                            </p>
                            <img className="productImage" src={CyeProduct} />
                        </Grid>
                    </Grid>
                </Grid>
                <div
                    className="catalogFooter mobile"
                    onClick={() =>
                        window.open(
                            "https://docs.google.com/forms/d/e/1FAIpQLSeegKk8iUdEz7xhK_UU_OB0ByOXyVqtUkMuJXGAY5A6zm55_g/viewform?usp=send_form"
                        )
                    }
                >
                    <img className="catalogFooterImage" src={SaneiLogoCatalog} />
                </div>
            </div>
        </>
    );
}

export default Mobile;
